import { Injectable, inject } from '@angular/core';
import { SelectOption } from '../../../shared/models/local/select-option';
import { Store } from '@ngrx/store';
import { ApiService } from '../../api/customer-drop/api.service';
import { Observable, map, take } from 'rxjs';
import { setDevice } from '../../../store/actions/device.actions';
import { Device } from '../../../shared/models/api/device';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  store = inject(Store);
  apiService = inject(ApiService);

  getStartUpData(did: string): void {
    this.apiService
      .getStartUpData(did)
      .pipe(
        take(1),
        map((response: Device) => ({
          id: did,
          country: response.country,
          nodeIdNo: response.nodeIdNo?.toString(),
          nodeCode: response.nodeCode,
          nodeName: response.nodeName,
          platformTenantCode: response.platformTenantCode,
        }))
      )
      .subscribe((device: Device) => {
        this.store.dispatch(setDevice({ device }));
      });
  }

  getNodeUsers(nodeIdNo: string): Observable<SelectOption[]> {
    return this.apiService.getNodeUsers(nodeIdNo).pipe(
      take(1),
      map((res) => {
        return res.map((user) => ({
          value: user.email!,
          text: user.displayName!,
          userId: user.userIdNo!.toString(),
        }));
      })
    );
  }
}
